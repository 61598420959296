//
// Alert
//
	
@each $name, $value in $theme-colors {
	.#{$name} {
		color: var(--kt-#{$name});
		border-color: var(--kt-#{$name});
		background-color: var(--kt-#{$name}-light);

		.link {
    		color: var(--kt-#{$name});
  		}
	}
}
