//
// Theme style
//

// Initialize
@import "init";

// Components
@import "./core/components/components";
@import "components/components";

// Layout
@import "./core/layout/base/layout";
@import "layout/layout";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');


body{
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 13px;
}

body, .app-default{
    background-color: #FAFAFA !important;
}

.app-sidebar{
    background-color: #0D4B88 !important;
}

.app-sidebar-logo{
    justify-content: flex-start;
    align-items: center;
    > a{
        position: relative;
        z-index: 2;
    }
}
.app-sidebar .app-sidebar-logo{
    border-bottom: 1px dashed #0d4b88 !important;
    padding-left: 15px !important;
    background: #1DA1F2;
}
.app-sidebar-logo-default, .app-sidebar-logo-minimize{
    height: 45px !important;
}

.app-sidebar-app-name{
    font-size: 16px;
    line-height: 1;
    text-shadow: 2px 2px 1px rgba(0,0,0,0.4);
    text-transform: uppercase;
    font-weight: 800;
    color: #FFF;
    margin-top: 5px;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.app-sidebar .menu .menu-item .menu-link{
    color: #FFF !important;
    padding-left: 5px;
}

.app-sidebar .menu .menu-item .menu-link i{
    font-size: 18px;
    padding: 10px;
    color: #FFF !important;
    margin-right: 10px;
    border-radius: 30px;
    // background-color: rgba(255,255,255,0.01);
    line-height: 20px;
    width: 40px;
    text-align: center;
}

.app-sidebar .menu .menu-item .menu-link .menu-title{
    color: #FFF !important;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 300;
}

.app-sidebar-toggle{
    z-index: 1;
    border-radius: 5px !important;
    box-shadow: 0px 0px 0px 4px #1da1f1 !important;
    margin-left: 0;
    transition: all 0.3s linear;
    &.active{
        margin-left: 8px;
    }
}

.app-sidebar .menu .menu-item .menu-link.active{
    background-color: rgba(0,0,0,0.1) !important;
}



.page-title{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #DDD;
    padding-bottom: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    .page-title-text{
        font-size: 18px;
        font-weight: 700;
        text-transform: uppercase;
    }
}

.btn{
    position: relative;
    padding: 0px 15px !important;
    height: 40px !important;
    line-height: 38px !important;
    border: none !important;
    font-size: 13px;
    white-space: nowrap;
    min-width: 40px;
    &.btn-light, &.btn-light-primary{
        line-height: 38px !important;
        border: 1px solid #AAA !important;

    }
}

.svg-icon-2 {
    margin-top: 5px;
    margin-right: -5px !important;
}

.app-toolbar{
    display: none !important;
}
.table-main-card{
    background-color: #FAFAFA;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #DDD;
}
.id-tag{
    line-height: 15px;
    padding: 0px 5px;
    font-size: 9px;
    background-color: #EEE;
    border: 1px solid #DDD;
    border-radius: 20px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
}

.table-status-tag{
    line-height: 12px;
    padding: 5px 10px;
    font-size: 12px;
    background-color: #ffd96f;
    border-radius: 5px;
    text-transform: capitalize;
    display: inline-block;
    font-weight: 400;
}
hr{
    margin: 10px 0px;
    border-color: #DDD;
}
.table-bold{
    font-size: 16px;
    font-weight: 600;
}

table{
    tbody{
        vertical-align: top !important;
    }
    tr{
        border-bottom: 1px solid #EEE !important;
    }
    .btn{
        margin: 3px !important;
    }
}

.menu-sub-indention .menu-sub:not([data-popper-placement]) {
    margin-left: 25px;
}


.table-top-head{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    background-color: #FFF;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.form-select{
    display: inline-block;
    height: 34px;
    padding: 0 10px !important;
    padding-right: 30px !important;
    background-position: right 10px center;
}
.form-control{
    padding: 0px 10px !important;
    height: 34px !important;
    line-height: 32px !important;
    font-size: 13px;
    background-color: #FFF !important;
    border-color: #DDD !important;
    &.alt{
        background-color: #FFF !important;
    }
    &.form-control-textarea{
        height: 150px !important;
    }
}

label{
    font-weight: 600;
}

.inline-form-select{
    display: flex;
    flex-direction: row;
    align-items: center;
    label{
        white-space: nowrap;
        margin-right: 10px;
    }
}

.table-responsive{
    background-color: #FFF;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

table{
    tr{
        td{
            padding: 10px !important;
        }
    }
}

.indicator-progress{
    margin: 50px;
    .spinner-border{
        border-color: #019df6 !important;
        border-right-color: transparent !important;
        animation-duration: 0.3s !important;
        height: 50px !important;
        width: 50px !important;
    }
}

.custom-card{
    background-color: #FFF;
    border-radius: 20px;
    padding: 20px;
    border: 1px solid #e1e0ff;
    &.alt{
        background-color: transparent;
        box-shadow: none;
        padding: 0px;
        border: none;
    }
}

section.custom-card{
    margin-bottom: 20px;
}

.custom-subcard{
    border: 1px solid #EEE;
    background-color: #FAFAFA;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    .form-control{
        border: 1px solid #EEE !important;
        background-color: #FFF !important;
    }
}

.custom-subcard-title{
    margin-top: 20px;
    font-size: 14px;
    font-weight: 900;
    color: #0d4b88;
    text-transform: uppercase;
    padding-left: 5px;
    margin-bottom: 2px;
}

.form-label{
    margin-bottom: 0px;
    padding-left: 5px;
    font-size: 12px !important;
    text-transform: uppercase;
    color: #1da1f1 !important;
}

.custom-card-section-title{
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    color: #1da1f1 !important;
}

.file-download-wrap{
    display: block;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    color: #000;
    &:last-child{
        margin-top: 20px;
        margin-bottom: 30px;
    }

    a{
        color: #000;
    }

    i{
        display: inline-block;
        font-size: 26px;
        margin-right: 10px;
        color: #1da1f1 !important;
        vertical-align: middle;
        &.red{
            color: #f00 !important;
        }
    }
}

.custom-card-item{
    margin-bottom: 20px;
    &:last-child{
        margin-bottom: 0px;
    }
    .custom-card-item-title{
        display: block;
        color: #1da1f1 !important;
    }
    .custom-card-item-value{
        display: block;
    }
}


.mr-10{
    margin-right: 10px !important;
}

.roof-drop-icon{
    position: absolute;
    top: 5px;
    right: 10px;
    svg{
        width: 10px !important;
    }
}


.rbc-toolbar .rbc-toolbar-label{
    font-size: 12px;
    font-weight: 900;
    text-transform: uppercase;
}

.rbc-toolbar button{
    font-size: 12px;
    padding: 5px 10px;
}


.rbc-show-more{
    font-size: 9px;
    line-height: 1;
    font-weight: 900;
    color: #FFF;
    background: #1e6cb8;
    background: red;
    padding: 5px 10px;
    border-radius: 20px;
    margin: 5px;
    text-transform: uppercase;
    margin: 5px auto;
}

.rbc-row-segment .rbc-event-content{
    font-weight: 800;
    font-size: 11px;
}

.table-media{
    height: 150px;
    width: auto;
    border-radius: 10px;
}

.rbc-time-view{
    overflow: auto;
}

.overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    z-index: 1232222342343242310;
    background-color: rgba(0, 0, 0, 0.267);
    &.active{
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
    }
}

.filter-wrap{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    padding: 20px;
    background-color: #FFF;
    width: 300px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transform: translateX(calc(100% + 20px));
    transition: all 0.2s ease-in-out;
    // display: none;
    z-index: 1232222342343242313;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    &.active{
        opacity: 1;
        visibility: visible;
        pointer-events: auto;
        transform: translateX(0px);
        // display: block;
    }
}


.project-card-cont{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.project-card-wrap{
    display: inline-flex;
    width: 33%;
    padding: 15px;
}
.employee-card-wrap{
    display: inline-flex;
    width: 33%;
    padding: 15px;
}

.project-card{
    background-color: #FFF;
    padding: 20px;
    width: 100%;
    // border: 2px solid #DDD;
    border-radius: 10px;
    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.thumb-btn{
    border: 1px solid #DDD !important;
}

.thumb-btn-green{
    border: 1px solid #00ff00 !important;
}

.thumb-btn-red{
    border: 1px solid #F00 !important;
}

.pagination{
    justify-content: center !important;
    margin: 30px 0;
    margin-bottom: 50px;
    border-top: 1px dashed #DDD;
    padding: 50px 0px;
}

.page-link{
    height: 50px !important;
    width: 50px;
    margin: 0px 30px !important;
    border: 2px solid #1ea1f1;
    color: #1ea1f1;
    span{
        font-size: 25px;
        font-weight: 900;
    }
}

.page-no-wrap{
    font-size: 15px;
    font-weight: 900;
    line-height: 50px;
    margin: 0 !important;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #1ea1f1;
    &::before{
        content: 'Showing page ';
    }
}

.custom-form-row{
    margin-bottom: 20px;
}
.empty-block-wrap{
    display: flex;
    width: 100%;
    justify-content: center;
}
.empty-block{
    display: flex;
    align-self: center;
    height: 300px;
    width: 300px;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FAFAFA;
    border: 1px dashed #DDD;
    border-radius: 20px;
}

.empty-block-text{
    font-size: 26px;
    text-align: center;
    font-weight: 400;
    color: #000;
    margin-top: 20px;
}